@import '../../../Styles/color.scss';

.sortAndSearchContainer {
  font-size: 14px;
  font-weight: 100;
  color: $titles-gray-color;
  font-weight: normal;
  .rowTitle {
    padding-bottom: 8px !important;
    padding-top: 8px !important;
  }
  .sortAndSearchTitle {
    color: $secondary-color;
  }
  .sortSection {
    margin-bottom: 8px;
    width: 100%;
    padding: 0;
  }
  .filterSection {
    width: 100%;
    padding: 0 !important;
  }
  .visibilitySection {
    width: 100%;
    padding: 0 !important;
    margin-bottom: 8px;
  }
}
