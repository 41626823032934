@import '../../../../Styles/screen-sizes.scss';

.remote-dropdown-container {
  .cursor {
    input.search {
      padding-left: 52px !important;
    }
  }
  .dropdown > .text {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

@media only screen and (max-width: $small-device) {
  .remote-dropdown-container {
    .cursor {
      input.search {
        padding-left: 50px !important;
      }
    }
  }
}
