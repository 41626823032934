@import '../../Styles/color.scss';
@import '../../Styles/mixin.scss';
@import '../../Styles/screen-sizes.scss';

.downloadOptions {
  position: relative !important;
  top: 6px !important;
  left: 14.5px !important;
  border: none !important;
  box-shadow: none !important;
  margin-top: 1px !important;
  padding: 0px !important;
  background: transparent !important;
  .popupContainer {
    .verticalMenu {
      border: none;
      @include shadow-container;
      width: 150px !important;
      border-radius: 15px !important;
      .menuItem {
        padding: 0;
        width: 100%;
        height: 100%;
        .pdfLabel {
          color: $labels-color;
          width: 100%;
          height: 100%;
          padding: 13px 20px;
        }
        &:hover {
          background-color: $option-hover;
          &:first-child {
            border-radius: 15px 15px 0 0;
          }
          &:last-child {
            border-radius: 0 0 15px 15px;
          }
        }
      }
    }
  }
}
