@import '../../Styles/color.scss';

.containerViewer {
  width: 100%;
  height: calc(100vh - 96px);
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background-color: $pdf-viewer-background;
  border-radius: 5px;

  .buttonDownload {
    max-width: 100px;
  }

  .loadingContainer {
    height: calc(100vh - 100px);
    border-radius: 5px;
  }

  .optionsContainer {
    background-color: #00000070;
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 10px 0;
    display: flex;
    justify-content: center;

    .options {
      width: 100%;
      max-width: 650px;
      padding: 0 10px;
      display: flex;
      justify-content: space-between;

      .downloadButton {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 42px;
        min-width: 132px;
      }

      .spinner > div {
        background-color: $white;
      }

      .downloadIcon {
        margin: 0 5px 0 0;
        height: 20px;
        width: 23px;
      }
    }
  }
}
