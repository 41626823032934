@import '../../../../Styles/color.scss';

.customDatepickerHeader {
  width: 90%;
  margin: 0 5% 5% 5%;
  display: flex;
  justify-content: center;

  .datepickerDropdown {
    text-align: center !important;
    margin: 0 4%;
  }

  .button {
    max-width: 24px;
    justify-content: center;
    background-color: $page-background-color;

    .svg {
      cursor: pointer;
      padding-top: 5%;
      margin-left: -6px;
      width: 16px;
      height: 16px;
    }
  }
}
