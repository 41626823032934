$mini-device: 320px; /* smartphones, iPhone, portrait 480x320 phones */
$tiny-device: 481px; /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
$small-device: 641px; /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
$medium-device: 768px;
$normal-device: 980px; /* tablet, landscape iPad, lo-res laptops ands desktops */
$tablet-size: 992px; /* tablet semantic grid px measure */
$large-device: 1182px; /* big landscape tablets, laptops, and desktops */
$huge-device: 1281px; /* hi-res laptops and desktops */

/******** Dashboard grid measures **********/
$one-card-grid-width: 289px;
$leftSideBarMenuWidth: 320px;
$dashboard-grid-max-huge-device-size: 1790px;
$dashboard-grid-min-huge-device-size: 1517px;
$dashboard-grid-max-large-device-size: 1228px;
$dashboard-grid-min-large-device-size: 993px;
$dashboard-grid-max-normal-device-size: 907px;
$dashboard-grid-min-normal-device-size: 618px;

/******** PDF template measures **********/
$template-card-width: 340px;
$max-width-two-template-cards: 650px;

/******** TopNavBar measures **********/
$heightTopNavBar: 68px;
$heightTopNavBarTwoRows: 96;

/******** TopNavBar measures **********/
$tablet-size-to-avoid-overlap: 991.5px;
