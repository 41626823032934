.avatar-name {
  margin-left: 8px;
  align-self: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.avatar-option {
  width: 26px;
  height: auto;
  line-height: 26px;
}

.option {
  display: grid;
  grid-template-columns: 15% 85%;
}
