@import '../color.scss';

.custom-datepicker-header {
  .menu {
    .item {
      text-align: center !important;
    }
  }
}

.react-datepicker__header {
  background-color: $page-background-color !important;
}
