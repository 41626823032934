@import '../../../../Styles/color.scss';
@import '../../../../Styles/screen-sizes.scss';

.share-pdf-cmpt {
  margin-top: 16px;
  margin-bottom: -24px;
  min-height: 25px;
  display: flex !important;
  flex-wrap: wrap;
  .options {
    height: 25px;
    align-items: center;
    display: flex !important;
    align-items: center;
  }

  label {
    padding: 0 4px;
    user-select: none;
    cursor: pointer;
  }

  .menu.transition.visible {
    min-width: 115px;
    border: none;
    margin-top: 10px;
    border-radius: 6px;
    div:first-child {
      border-radius: 6px 6px 0 0;
    }

    div:last-child {
      border-radius: 0 0 6px 6px;
    }

    .item .description {
      opacity: 0;
    }

    .item:hover {
      .description {
        opacity: 1;
      }
    }
  }

  .text-Area {
    height: 0;
    width: 0;
    opacity: 0;
    resize: none;
    overflow: hidden;
    cursor: pointer;  
  }

  .copy-message {
    padding: 4px !important;
    margin: 0px 4px !important;
    height: 24px;
  }
}

@media only screen and (max-width: $mini-device) {
  .share-pdf-cmpt {
    min-height: 52px;
  }
}
