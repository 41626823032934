.Toastify__close-button {
  padding-right: 8px;
}

.Toastify__toast--success,
.Toastify__toast--warning,
.Toastify__toast--error,
.Toastify__toast--info {
  border-radius: 8px;
  .Toastify__close-button {
    min-width: 25px;
    svg {
      margin-left: -4px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.message-content {
  display: flex;
  flex-direction: row;
  span {
    min-width: 28px;
    svg {
      width: 22px;
      height: 22px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  p {
    margin-left: -4x;
    margin-top: 1px;
  }
}
