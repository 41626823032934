@import '../../Styles/color.scss';
@import '../../Styles/screen-sizes.scss';

.menuToggle {
  display: block;
  margin-left: 4px;
  z-index: 1;

  -webkit-user-select: none;
  user-select: none;

  input {
    display: block;
    position: absolute;
    width: 26px;
    height: 20px;
    left: 15px;

    cursor: pointer;
    opacity: 0;
    z-index: 3;
    visibility: hidden;
  }

  .hamburgerIcon {
    position: absolute;
    stroke: $secondary-color;
    height: 28px;
    width: 28px;
    top: -14px;
    cursor: pointer;
  }

  .sidebar {
    position: fixed;
    width: 280px;
    background-color: $hamburger-background-color;
    box-shadow: 0px 0 20px -8px rgb(136, 136, 136);
    margin: -45px 0 0 -18px;
    padding: 0px 16px;
    z-index: 2;
    min-height: 120vh;
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

    .header {
      padding: 8px 0px;
      height: 40px;
      .closeIcon {
        width: 26px;
        height: 26px;
        float: right;
        stroke: $icons-gray-color;
        cursor: pointer;
      }
    }

    .body {
      width: 100%;
      margin-left: 20px;
    }
  }

  input:checked ~ div {
    transform: none;
  }
}

@media only screen and (min-width: $medium-device) and (max-width: $tablet-size - 1) {
  .menuToggle {
    input {
      top: -48px;
      right: 4px;
    }

    .hamburgerIcon {
      top: -38px;
    }
  }

  .sidebar {
    margin: -80px 0 0 -34px !important;
  }
}

@media only screen and (min-width: $mini-device) and (max-width: $small-device) {
  .menuToggle {
    .sidebar .body {
      margin-left: 0;
    }
  }
}
