@import '../../../../Styles/color.scss';
@import '../../../../Styles/screen-sizes.scss';

.label-shared {
  margin-bottom: 5px;
  label {
    color: $labels-color;
  }
}

.modal-container {
  cursor: initial;
}

.shared-resume-control {
  .ui.dropdown .menu {
    max-width: 250px !important;
    min-width: min-content !important;
  }
  .ui.dropdown:hover .menu {
    width: 250px !important;
    min-width: min-content !important;
  }

  .dropdown {
    .text,
    .label {
      max-width: 100%;
    }
  }

  .addition {
    min-width: 250px !important;
    max-width: auto !important;
  }

  #shared-resume-items i.dropdown.icon {
    top: 0;
  }
}

@media only screen and (max-width: $small-device) {
  .shared-resume-control {
    .ui.selection.active.dropdown .menu {
      width: 100% !important;
      min-width: auto !important;
      left: 0 !important;
    }
    .ui.selection.active.dropdown:hover .menu {
      width: 100% !important;
      min-width: auto !important;
      left: 0 !important;
    }
  }
}

.ui.modal > .header {
  padding-bottom: 10px !important;
}

.ui.modal.transition.visible.active {
  padding: 30px;
}

.ui.modal > .header {
  color: $secondary-color !important;
  border-bottom: none !important;
}

.ui.modal > .actions {
  border-top: none !important;
  background: $white !important;
  .shared-button {
    background: $modal-button-color;
    color: $white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 9px 21px;
    .group-icon {
      margin: 0 6px 0 0;
      height: 21px;
      width: 23px;
    }
    .text-button {
      vertical-align: text-bottom;
      font-size: 14px;
    }
  }
}

.share-icon {
  color: $disabled-icon-color;
  cursor: pointer;
  &:hover {
    color: $hover-icon-color;
  }
}

.active-icon {
  color: $primary-color;
  &:hover {
    color: $primary-color
  }
}

@media only screen and (max-width: $tiny-device) {
  .share-icon {
    width: 24px;
  }
}
