@import '../../Styles/color.scss';

#ResumeOptions {
  #iconSection {
    .card-dropdown {
      animation: fade-in 0.6s;
      .card-menu {
        margin-bottom: 15px;
        left: -100px;
        border-radius: 8px;
        .item {
          width: 100%;
          padding: 6px !important;
          display: flex;
          flex-flow: row;
          .title,
          .text {
            padding-left: 9px;
            font-size: 14px;
            float: left;
            width: 80px;
            margin: 0px !important;
          }
          .option-icon {
            width: 10%;
            margin-top: 5px;
            margin-left: 10px;
            color: #1ab2eb !important;
          }
          .custom-icon-option {
            margin: 0px 8px 0px 10px;
            width: 15px;
            max-height: 15px;
            stroke: $primary-color;
          }
        }

        .active.item {
          font-weight: normal;
        }

        .card-option:first-child,
        .sub-menu .item:first-child {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }

        .card-option:last-child,
        .sub-menu .item:last-child {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        .shared-pdf-hover {
          background-color: $option-hover;
        }

        #share-option {
          svg {
            margin-left: 7px;
          }

          .sub-menu::after {
            opacity: 0;
          }

          .sub-menu {
            height: max-content;
            border-radius: 8px !important;
            margin: 0px !important;
            .item {
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
