@import '../../Styles/color.scss';

.HightLightColorContainer {
  height: 42px;
  width: 224px;
  background-color: $white;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding: 0 4px 0 4px;
  .iconSection {
    width: 52px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 24px;
      width: 24px;
    }
  }
  .colorSection {
    width: calc(100% - 58px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .picked {
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin: 0 2px;
      cursor: pointer;
    }
    .chooseColor {
      width: 18px;
      height: 18px;
      border-radius: 50%;
    }
  }
}
