@import '../../../../Styles/screen-sizes.scss';

.form-education-container {
  margin: 25px 15px;
}

.dates-container {
  width: calc(100% + 2px);
  margin-top: 0 !important;
  margin-bottom: 2px !important;
}

@media only screen and (max-width: $small-device) {
  .form-education-container {
    margin: 15px 0px;
  }

  .dates-container {
    margin-bottom: 4px !important;
  }
}
