@import '../../Styles/screen-sizes.scss';
@import '../../Styles/color.scss';

.profileContainer {
  cursor: pointer;
}

.userPicture {
  min-width: 38px;
  max-width: 38px;
  height: 38px;
  left: 15px;
  border-radius: 50%;
}

.letterAvatar {
  left: 15px;
  position: relative;
}

.userOptions {
  padding: 3.2px 0px !important;
  margin-top: 3.5px !important;
  position: relative !important;
  left: 26px !important;
  border: none !important;

  .option {
    min-width: 152px;
    min-height: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    .optionIcon {
      height: 20px;
      width: 20px;
      margin: 5px;
      width: 25px;
    }

    .optionText {
      margin-right: 8px;
    }
    &:hover {
      border-radius: 4px !important;
      background-color: $option-hover !important;
    }
  }
}

@media only screen and (max-width: $medium-device) {
  .userPicture {
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    left: 0px;
  }

  .letterAvatar {
    left: 0px;
  }

  .userOptions {
    margin-top: 2.5px !important;
    left: 5px !important;
    .option {
      min-width: 120px;
    }
  }
}

@media only screen and (width: $medium-device) {
  .userPicture {
    left: 22px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1028px) {
  .userPicture {
    left: 10px;
  }
}
