@import '../../Styles/color.scss';
@import '../../Styles/screen-sizes.scss';

.accordion-container {
  margin-top: 20px;
  .accordion {
    margin-left: -20px;
    width: calc(100% + 20px) !important;
    & > div {
      margin-right: -20px;
    }
  }
  .ui.button.new-entry-button {
    margin-top: 30px;
    padding-left: 0;
  }
}

.draggable-item-container {
  display: flex;
}

.draggable-item-container:hover .draggable-icon-container {
  transition: all ease 0.5s;
  opacity: 1;
}

.draggable-icon-container {
  width: 20px;
  margin-top: 20px;
  opacity: 0;
}

.drag-drop-icon {
  stroke: $disabled-icon-color;
}

.individual-accordion-container {
  background-color: $white;
  width: calc(100% - 20px);
  border-radius: 5px;
  border: 1px solid $accordion-item-border-color;
  padding: 10px 20px 10px 20px;
}

.titles-container {
  display: flex;
  .section-left {
    display: flex;
  }
  .accordion-main-title {
    display: flex;
  }
  .stackable.grid {
    .column {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.accordion-title,
.accordion-secondary-title,
.accordion-third-title {
  word-break: break-word;
  padding-left: 10px;
}

.eye-icon-container {
  margin-left: auto;
}

.helpers-icon-container {
  margin-left: auto;
  min-width: 58px;
}

i.icon.eye-icon {
  margin-right: 20px;
}

@media only screen and (max-width: $medium-device) {
  .accordion-title,
  .accordion-secondary-title,
  .accordion-third-title {
    font-size: 12px;
  }
}

@media only screen and (max-width: $small-device) {
  .draggable-icon-container {
    margin-top: 15px;
    opacity: 1;
  }

  .individual-accordion-container {
    padding: 5px 10px 5px 10px;
  }

  .titles-container {
    .accordion-main-title {
      display: block;
    }
    .eye-icon {
      margin-right: 5px !important;
    }
    .helpers-icon-container {
      text-align: right;
    }
  }

  .new-entry-button {
    margin-top: 15px !important;
  }
}
