@import '../../../Styles/color.scss';
@import '../../../Styles/screen-sizes.scss';

.ui.container.container-dashboard {
  width: $large-device;
  padding: 0 20px;
  box-sizing: border-box;
  height: calc(100vh - 68px);
  overflow: hidden;

  .dashboard-grid {
    height: 100%;
    margin-top: 0;
    .sort-and-search-wrapper {
      width: $leftSideBarMenuWidth !important;
      padding-left: 55px !important;
      background-color: $search-labels-color;
      padding-top: 36px !important;
      height: 100%;
      overflow-y: auto;
      #sortSection,
      #filterSection {
        padding-left: 0 !important;
      }
    }
  }

  .sort-and-search-wrapper.hide {
    display: none;
  }

  .ui.grid > .column:not(.row),
  .ui.grid > .row > .column {
    width: calc(100% - 320px);
  }

  .dashboard-wrapper {
    padding: 0 !important;
    height: calc(100vh - 82px);
    .search-container {
      display: flex;
      position: fixed;
      z-index: 6 !important;
      justify-content: center;
      width: calc(100% - #{$leftSideBarMenuWidth});
      height: 97px;
      background-color: $page-background-color;
      padding-top: 28px !important;
      padding-right: 0px !important;
      padding-left: 30px !important;
      top: 69px;
      .nav-bar-search-show {
        width: 1130px;
        position: relative;
        .input {
          width: 100% !important;
          left: -20px;
          background: none;
          input {
            border: none;
          }
          .label,
          .label::before {
            padding-left: 18px;
            padding-right: 4px;
            padding-top: 12px;
            padding-bottom: 12px;
            border-radius: 20px 0 0 20px;
            background: $white;
          }
          .button,
          .button::before {
            padding-right: 18px;
            padding-left: 4px;
            padding-top: 12px;
            padding-bottom: 12px;
            border-radius: 0 20px 20px 0;
            background: $white;
          }
        }
        .input:focus-within {
          border: 1px solid $input-focus-border-color;
          border-radius: 200px;
        }
        .search-icon {
          height: 15px;
          width: 15px;
        }
        .delete-icon {
          height: 15px;
          width: 15px;
          stroke: #009dff;
        }
      }
      .filter.large.icon {
        cursor: pointer;
        color: $modal-button-color;
      }
    }
    .resumes-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      min-height: calc(100vh - 165px);
      height: calc(100% - 86px);
      margin-top: 100px;
      overflow-y: auto;
      .ui.cards {
        max-width: 1156px;
      }
    }

    .hide {
      display: none;
    }
  }
}

@media only screen and (max-width: $tablet-size) {
  .ui.container.container-dashboard {
    .dashboard-grid {
      padding-top: 0px !important;
      .dashboard-wrapper {
        .menu {
          margin: 0px !important;
        }
        .search-container {
          width: 100%;
          height: 36px;
          top: 30px;
          position: relative;
          padding: 0 !important;
          .nav-bar-search-show {
            width: 100%;
            margin-right: 0px;
          }
          .input {
            width: 100% !important;
            left: 0;
          }
          .filter-icon {
            width: 26px;
            height: 32px;
            margin-left: 4px;
            stroke-width: 1.65;
            display: flex;
            margin-top: 3px !important;
            justify-content: flex-end;
            cursor: pointer;
          }
        }
      }
      .resumes-container {
        margin-top: 64px;
      }
    }
    .sort-and-search-wrapper {
      display: none !important;
    }
  }
}

@media only screen and (min-width: $dashboard-grid-max-huge-device-size) {
  .container-dashboard {
    .dashboard-wrapper {
      width: calc(100% - #{$leftSideBarMenuWidth}) !important;
      .ui.cards {
        width: ($one-card-grid-width * 4);
      }
    }
  }
}

@media only screen and (max-width: $medium-device) {
  .container-dashboard {
    height: calc(100% - 96px) !important;
  }
  .resumes-container {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: $tiny-device) {
  .container-dashboard {
    height: calc(100% - 96px) !important;
  }
  .resumes-container::-webkit-scrollbar {
    width: 4px;
  }
}

@media only screen and (min-width: $dashboard-grid-min-huge-device-size) and (max-width: $dashboard-grid-max-huge-device-size - 1) {
  .container-dashboard {
    .ui.cards {
      width: ($one-card-grid-width * 4);
    }
  }
}

@media only screen and (min-width: $dashboard-grid-max-large-device-size + 1) and (max-width: $dashboard-grid-min-huge-device-size - 1) {
  .container-dashboard {
    .dashboard-wrapper {
      width: calc(100% - #{$leftSideBarMenuWidth}) !important;
      .ui.cards {
        width: ($one-card-grid-width * 3);
      }
    }
    .search-container {
      .nav-bar-search-show {
        width: ($one-card-grid-width * 3) - 20 !important;
      }
    }
  }
}

@media only screen and (min-width: $dashboard-grid-min-large-device-size) and (max-width: $dashboard-grid-max-large-device-size) {
  .container-dashboard {
    .dashboard-wrapper {
      width: calc(100% - #{$leftSideBarMenuWidth}) !important;
      .ui.cards {
        width: ($one-card-grid-width * 2);
      }
    }
    .search-container {
      .nav-bar-search-show {
        width: ($one-card-grid-width * 2) - 20 !important;
      }
    }
  }
}

@media only screen and (min-width: $dashboard-grid-max-normal-device-size + 1) and (max-width: $dashboard-grid-min-large-device-size - 1) {
  .container-dashboard {
    .dashboard-wrapper {
      width: 100% !important;
      .menu {
        padding: 0 calc((100% - (#{$one-card-grid-width} * 3)) / 2) !important;
      }
      .ui.cards {
        width: ($one-card-grid-width * 3);
      }
      .nav-bar-search-show {
        width: ($one-card-grid-width * 3) - 54 !important;
      }
    }
  }
}

@media only screen and (min-width: $dashboard-grid-min-normal-device-size) and (max-width: $dashboard-grid-max-normal-device-size) {
  .container-dashboard {
    .dashboard-wrapper {
      width: 100% !important;
      .menu {
        padding: 0 calc((100% - (#{$one-card-grid-width} * 2)) / 2) !important;
      }
      .ui.cards {
        width: ($one-card-grid-width * 2);
      }
      .nav-bar-search-show {
        width: ($one-card-grid-width * 2) - 54 !important;
      }
    }
  }
}

@media only screen and (max-width: $dashboard-grid-min-normal-device-size - 1) {
  .container-dashboard {
    .dashboard-wrapper {
      width: 100% !important;
      .menu {
        padding: 0 calc((100% - (#{$one-card-grid-width})) / 2) !important;
      }
      .ui.cards {
        display: initial !important;
        width: $one-card-grid-width;
      }
    }
  }
}

@media only screen and (min-width: $mini-device) and (max-width: $small-device) {
  .ui.container.container-dashboard {
    .dashboard-grid .dashboard-wrapper.column {
      padding-right: 10px !important;
    }
  }
}

@media only screen and (max-width: $tiny-device) {
  .ui.container.container-dashboard {
    .dashboard-grid {
      .dashboard-wrapper {
        padding: 0 0 0 10px !important;
        .search-container {
          padding: 0 0px 0 0 !important;
          .nav-bar-search-show {
            .input {
              input {
                padding-left: 4px;
                padding-right: 0px;
              }
            }
          }
        }
        .menu {
          padding: 0px !important;
        }
      }
      .ui.cards {
        display: initial !important;
        padding: 0 4% !important;
        max-width: 100% !important;
        width: 100%;
        padding: 0 !important;
      }
    }
  }
}

.search-modal {
  padding: 28px 16px !important;
  border-radius: 10px !important;
  .search-modal-title {
    font-size: 24px;
    font-weight: normal;
    color: $search-modal-labels-color;
    margin-bottom: 8px;
  }
  .close-icon {
    top: 1.5rem !important;
    right: 1.5rem !important;
    position: absolute;
  }
}
