@import '../../../../Styles/screen-sizes.scss';
@import '../../../../Styles/color.scss';

.form-work-section-container {
  margin: 25px 15px;
  .field {
    label,
    .work-experience-tooltip-icon {
      display: inline-block !important;
    }
  }
}

.ui.form {
  .form-work-section-container {
    label,
    .work-experience-tooltip-icon {
      display: inline-block !important;
    }
    
    .work-experience-tooltip-icon.optional-field:after {
      content: '(Optional)';
      padding-left: 10px;
      font-size: 10px;
    }
  }
}

.work-experience-tooltip-header {
  display: flex;
}

.work-experience-tooltip-icon {
  flex-grow: 1;
  text-align: unset !important;
  margin-left: 5px;
}

.form-work-section-container {
  .ui.label,
  .ui.label:hover,
  .ui.active.label:hover {
    background-color: $tags-background-color;
    color: $white;
    font-weight: normal;
  }

  .ui.multiple.dropdown > .label {
    box-shadow: none;
  }

  .dropdown {
    .text,
    .label {
      max-width: 100%;
    }
    .menu .item,
    .text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.work-experience-dates {
  .client {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;

    .sixteen.wide.field {
      padding-right: 0 !important;
      width: calc(100% + 5px) !important;
    }
  }
}

div#jobTitle > .text,
div#profile > .text {
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 100%;
}

@media only screen and (max-width: $small-device) {
  .form-work-section-container {
    margin: 10px 0px;
  }
}
