@import '../../Styles/screen-sizes.scss';
@import '../../Styles/color.scss';

.customModal {
  border-radius: 15px !important;
  cursor: initial;
  user-select: none;
}

.modalTitle {
  font-size: 22px !important;
}

.modalDescription {
  color: $description-color;
}

.confirmButton {
  opacity: 0.6;
  color: $white !important;
  background-color: $modal-button-color !important;
  &:hover {
    opacity: 1;
  }
  .buttonIcon {
    color: $white !important;
  }
}

@media (max-width: $small-device) {
  .confirmButton {
    opacity: 1;
  }
}
