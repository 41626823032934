@import '../../Styles/color.scss';
@import '../../Styles/mixin.scss';
@import '../../Styles/screen-sizes.scss';

.containerTooltip {
  padding: 0 !important;
  margin-top: 84px !important;
  border-radius: 15px !important;
  border: none !important;
  @include shadow-container;

  .containerMenu {
    padding: 7px 15px;
    min-width: 200px;
    min-height: 44px;
    display: flex;
    align-items: center;
    .menuTitle {
      color: $hover-icon-color;
      display: inline-block;
      margin-left: 8px;
      vertical-align: 5px;
    }
    .progressIcon {
      width: 20px;
      height: 20px;
    }
    .hide {
      color: transparent;
    }
    &:hover {
      cursor: pointer;
      background-color: $page-background-color;
    }
  }
  a:first-child {
    .containerMenu {
      padding-top: 12px;
      border-radius: 15px 15px 0 0;
    }
  }

  .tooltipDivider {
    margin: 0.5rem 0px !important;
    border-top: 1px solid $divider-color !important;
  }

  span {
    .containerMenu {
      padding: 0px 12px 10px;
      &:hover {
        cursor: unset;
        background-color: unset;
      }
    }
  }
  .iconSuccess {
    color: $success-icons-color;
  }
  .iconWarning {
    color: $warning-icons-color;
  }
}

.numberIconContainer {
  display: flex;
  svg {
    height: 20px;
    width: 20px;
    stroke: $description-color;
  }
}

@media only screen and (max-width: $tiny-device) {
  .containerTooltip {
    .containerMenu {
      .menuTitle {
        font-size: 12px;
      }
    }
  }
}
