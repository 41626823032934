@import '../../Styles/color.scss';
@import '../../Styles/screen-sizes.scss';

.pagination-container {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  .page {
    background-color: $white;
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin: 0px 6px;
    box-shadow: 0 0 20px $page-button-shadow-color !important;
    -webkit-box-shadow: 0px 0px 20px $page-button-shadow-color !important;
    -moz-box-shadow: 0px 0px 20px $page-button-shadow-color !important;
    cursor: pointer;
    user-select: none;

    &:hover {
      font-weight: bold;
    }

    &.active {
      background: $black;
      color: $white;
      font-weight: bold;
    }
  }

  .chevron {
    height: 28px;
    width: 28px;
    stroke: $black;
    stroke-width: 2px;
    opacity: 0.7;
    cursor: pointer;
    user-select: none;
    
      &:hover {
        opacity: 1;
      }
    
      &.disable {
        stroke: $disabled-icon-color !important;
        opacity: 1;
      }
  }
}

@media only screen and (max-device-width: $tiny-device) {
  .pagination-container {
    .page {
      background-color: $white;
      height: 22px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      margin: 0px 4px;
      box-shadow: 0 0 20px $page-button-shadow-color !important;
      -webkit-box-shadow: 0px 0px 20px $page-button-shadow-color !important;
      -moz-box-shadow: 0px 0px 20px $page-button-shadow-color !important;
      cursor: pointer;
      user-select: none;
    }
  }
}
