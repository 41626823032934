@import '../../../Styles/color.scss';
@import '../../../Styles/screen-sizes.scss';

.modal-merge-resume-container {
  cursor: initial;
  .merge-avatar {
    height: 30px;
    width: 30px;
    line-height: 30px;
    margin-right: 10.5px;
    max-height: 32px;
    font-size: 12px;
  }

  .ui.menu {
    border: none;
  }

  .content-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }
  .option-content {
    display: flex;
    .ui.header > .image:not(.icon),
    .ui.header > img {
      max-height: 32px !important;
    }
  }
  .vertical-menu {
    max-width: 50% !important;
    min-width: 50% !important;
  }
  .email-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}

@media only screen and (max-width: $medium-device) {
  .modal-merge-resume-container {
    .vertical-menu {
      max-width: 100% !important;
    }
    .merge-avatar {
      font-size: 11px;
    }
  }
}
