@import '../../../../Styles/color.scss';

.reactQuillColor {
  border-radius: 0.3rem;
  background-color: $page-background-color;
  border: 1px solid $page-background-color;
}

.reactQuillColor.reactQuillError {
  box-shadow: none;
  border-color: $secondary-color;
}

:global {
  .ql-toolbar.ql-snow {
    border: none;
  }

  .ql-container.ql-snow {
    min-height: 80px;
    border: none;
    .ql-editor {
      word-break: break-word;
    }
  }
}
