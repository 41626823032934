@import '../../Styles/color.scss';
@import '../../Styles/mixin.scss';
@import '../../Styles/screen-sizes.scss';

.card-container {
  @include shadow-container;
}

.card-container-active {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08) !important;
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08) !important;
  -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08) !important;
}

.card-container-active,
.card-container {
  border-radius: 10px;
}

.fluid.card.card-container-active,
.fluid.card.card-container {
  padding: 40px;
}

.optional-field {
  label:after {
    content: '(Optional)';
    margin-left: 10px;
  }
}

.card-header {
  display: grid;
  grid-template-columns: 1fr 0.1fr;
  .progress-icon {
    width: 24px;
    height: 24px;
  }
}

.card-description {
  margin: 8px 0 20px;
}

.header-icon {
  justify-self: end;
}

.warning-icon {
  color: $warning-icons-color;
  font-size: 1.2rem !important;
}

.success-icon {
  color: $success-icons-color;
  font-size: 1.2rem !important;
}

.ui.fluid.card.card-container,
.ui.fluid.card.card-container-active {
  min-width: 100%;
  max-width: 100px !important;
}

@media only screen and (max-width: $small-device) {
  .fluid.card.card-container-active,
  .fluid.card.card-container {
    padding: 25px;
  }
  .card-header {
    margin-top: 20px;
  }

  .header-icon {
    margin-right: 20px;
  }

  .header-title {
    padding-left: 20px;
  }
}
