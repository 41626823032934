/*******************************
        Mon-CV colors pallette
*******************************/
$primary-color: #1AB2EB;
$secondary-color: #FE6B76;
$page-background-color: #F6F8F9;

/*******************************
        Sections colors
*******************************/
$white: #FFFFFF;
$black: #000000;
$divider-color: #EBEBEB;
$tags-background-color: #A2A2A2;
$pdf-viewer-background: #565656;
$accordion-item-border-color: #E8E8E8;
$page-button-shadow-color: #00000014;
$progress-top-bar-hover-gray: #EEEEEE;
$option-hover: #F2F2F2;
$hamburger-background-color: #F2F4F5;

/*******************************
Icons and text colors
*******************************/
$labels-color: #7D7D7D;
$disabled-text: #9FA1A0;
$disabled-icon-color: #D3D3D3;
$icons-gray-color: #C1C1C1;
$helper-icon-color: #7E7E7E;
$hover-icon-color: $labels-color;
$description-color: #7E7E7E;
$titles-gray-color: #4E4E4E;
$subtitles-gray-color: #9A9A9A;
$menu-options-gray-color: #909090;
$search-labels-color: #F2F4F5;
$search-modal-labels-color: #9D9D9D;

/*-------------------
Forms Colors
--------------------*/
$input-label-color: $helper-icon-color;
$input-focus-border-color: #85B7D9;
$optional-label-color: #C0C0C0;
$error-item-border-color: #FE6B76;
$input-text-color: #000000DE;
$avatar-background-color: #B7ECFC;
$drop-down-border-blue: #CCE2FF;

/*-------------------
     Modals Colors
--------------------*/
$modal-button-color: #009DFF;
$modal-border-color: #F1F3F4;
$modal-background-color-template: #00000060;

/*-------------------
     Info Colors
--------------------*/
$success-container-background: #DFF2BF;
$warning-container-background: #FEEFB3;
$error-container-background: #FFD2D2;
$info-container-background: #BDE5F8;
$success-text-color: #4F8A10;
$warning-text-color: #9F6000;
$error-text-color: #D8000C;
$info-text-color: #00529B;
$success-icons-color: #34E664;
$warning-icons-color: #FFD000;
$hidden-background: #FFFFFF64;
