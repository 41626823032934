@import './color.scss';
@import './screen-sizes.scss';

/*-------------------
     Devices Sizes
--------------------*/
$miniDevice: 320px;
$tinyDevice: 481px;
$smallDevice: 641px;
$mediumDevice: 768px;
$normalDevice: 980px;
$largeDevice: 1182px;
$hugeDevice: 1281px;

/*-------------------
     Fonts Size
--------------------*/
$optionalSize: 10px;
$descriptionSize: 12px;
$emSize: 14px;
$fontSize: 14px;
$fontSizeHeader: 16px;
$fontSizeHeaderCard: 22px;
$small-device: 641px;
$medium-device: 768px;

/*-------------------
     Fonts
--------------------*/

$fontName: 'Roboto';
$headerfont: $fontName, sans-serif;
$pagefont: $fontName, sans-serif;
$fontsmoothing: antialiased;

$importgooglefonts: true;
$googlefontname: $fontName;
$googlefontsizes: '400,700,400italic,700italic';
$googlefontfamily: '${googleFontName}:${googleFontSizes}';

/*-------------------
     Component override styles
--------------------*/

$progressBarConstant: 111;

body #root {
  height: 100%;
}

.success-icon {
  stroke: $success-icons-color;
}

.warning-icon {
  stroke: $warning-icons-color;
}

.ui.form .ui.input input[type='text']:focus {
  border-color: $input-focus-border-color;
}

.ui.form .error .ui.input input[type='text']:focus {
  border-color: $error-item-border-color;
}

.ui.form .field .work-experience-tooltip-header > label,
.ui.form .field > label,
.ui.form .field > .profesional-summary-header > label {
  font-size: $descriptionSize;
  color: $input-label-color;
  font-weight: normal;
}

.ui.form .fields .wide.field.optional-field label:after,
.ui.form .field.optional-field label:after {
  color: $optional-label-color;
  font-size: $optionalSize;
}

.ui.form .field.field input:-webkit-autofill,
.ui.form .field.field input:-webkit-autofill:focus {
  border-color: transparent !important;
  box-shadow: none;
}

.ui.form .fields.error .field label,
.ui.form .field.error label,
.ui.form .fields.error .field .input,
.ui.form .field.error .input {
  color: $input-label-color;
}

.ui.form .fields.error .field textarea,
.ui.form .fields.error .field select,
.ui.form .fields.error .field input:not([type]),
.ui.form .fields.error .field input[type='date'],
.ui.form .fields.error .field input[type='datetime-local'],
.ui.form .fields.error .field input[type='email'],
.ui.form .fields.error .field input[type='number'],
.ui.form .fields.error .field input[type='password'],
.ui.form .fields.error .field input[type='search'],
.ui.form .fields.error .field input[type='tel'],
.ui.form .fields.error .field input[type='time'],
.ui.form .fields.error .field input[type='text'],
.ui.form .fields.error .field input[type='file'],
.ui.form .fields.error .field input[type='url'],
.ui.form .field.error textarea,
.ui.form .field.error select,
.ui.form .field.error input:not([type]),
.ui.form .field.error input[type='date'],
.ui.form .field.error input[type='datetime-local'],
.ui.form .field.error input[type='email'],
.ui.form .field.error input[type='number'],
.ui.form .field.error input[type='password'],
.ui.form .field.error input[type='search'],
.ui.form .field.error input[type='tel'],
.ui.form .field.error input[type='time'],
.ui.form .field.error input[type='text'],
.ui.form .field.error input[type='file'],
.ui.form .field.error input[type='url'] {
  border-color: $error-item-border-color !important;
  background: $page-background-color;
  color: green;
}

.ui.form .error .ui.input input[type='text']:focus {
  border-color: $error-item-border-color;
  background: $page-background-color;
  color: $input-text-color;
}

.ui.form .form-personal-info-container .field.hide-picture:focus {
  outline-color: $input-focus-border-color;
}

.ui.secondary.pointing.menu {
  border-bottom: none;
}

.ui.button.new-entry-button {
  color: $primary-color;
  background-color: white;
}

.ui.container {
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

h2.ui.header {
  color: $primary-color;
}
.ui.header.header-title {
  color: $secondary-color;
  font-size: $fontSizeHeaderCard;
  font-weight: bold;
  margin: 0;
}

i.search.icon {
  color: $primary-color;
}
i.trash.icon,
i.dropdown-icon {
  color: $primary-color;
}

i.eye-slash.icon,
i.dropdown-icon {
  color: $primary-color;
}

i.circle.outline.icon {
  color: $disabled-icon-color;
}
i.check.circle.outline.icon {
  color: $primary-color;
}
i.eye.icon {
  color: $icons-gray-color;
}
i.question.icon {
  color: $helper-icon-color;
}

.ui.mini.image {
  width: 40px;
}

.ui.input > input {
  border: 1px solid white;
}
.ui.form .ui.input input[type='text'] {
  background: $page-background-color;
  border-color: transparent;
}

.form-personal-info-container {
  .label-header {
    label {
      color: $input-label-color;
    }
  }
}

.ql-container {
  font-family: $fontName, sans-serif !important;
}

.circle-progress-bar,
.some {
  svg circle {
    &:nth-child(1) {
      stroke-dashoffset: 0;
      stroke: $disabled-icon-color;
    }
    &[class^='circle-'] {
      stroke-dashoffset: calc(#{$progressBarConstant} - (#{$progressBarConstant} * (0)) / 100);
      stroke: $primary-color;
    }
    &[class^='circle-10'] {
      stroke-dashoffset: calc(#{$progressBarConstant} - (#{$progressBarConstant} * 10) / 100);
    }
    &[class^='circle-20'] {
      stroke-dashoffset: calc(#{$progressBarConstant} - (#{$progressBarConstant} * 20) / 100);
    }
    &[class^='circle-30'] {
      stroke-dashoffset: calc(#{$progressBarConstant} - (#{$progressBarConstant} * 30) / 100);
    }
    &[class^='circle-40'] {
      stroke-dashoffset: calc(#{$progressBarConstant} - (#{$progressBarConstant} * 40) / 100);
    }
    &[class^='circle-50'] {
      stroke-dashoffset: calc(#{$progressBarConstant} - (#{$progressBarConstant} * 50) / 100);
    }
    &[class^='circle-60'] {
      stroke-dashoffset: calc(#{$progressBarConstant} - (#{$progressBarConstant} * 60) / 100);
    }
    &[class^='circle-70'] {
      stroke-dashoffset: calc(#{$progressBarConstant} - (#{$progressBarConstant} * 70) / 100);
    }
    &[class^='circle-80'] {
      stroke-dashoffset: calc(#{$progressBarConstant} - (#{$progressBarConstant} * 80) / 100);
    }
    &[class^='circle-90'] {
      stroke-dashoffset: calc(#{$progressBarConstant} - (#{$progressBarConstant} * 90) / 100);
    }
    &[class^='circle-100'] {
      stroke-dashoffset: calc(#{$progressBarConstant} - (#{$progressBarConstant} * 100) / 100);
    }
  }
  .percent {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $hover-icon-color;
    font-size: 11px;
  }
  &:hover {
    cursor: pointer;
  }
}

.progress-side-bar-table.card-container .side-bar-download {
  line,
  polyline,
  path {
    stroke: $primary-color;
  }
}

.pagination-container .page.active {
  background: $secondary-color;
}

.pagination-container .chevron {
  stroke: $primary-color;
}

.progress-top-bar-root-container {
  background-color: $page-background-color !important;
}

label.progress-top-bar-section-selected {
  color: $primary-color !important;
}

.started-section-color {
  background-color: $primary-color !important;
}

.datepicker-label {
  color: $input-label-color;
}

.react-datepicker-wrapper {
  .datepicker {
    background-color: $page-background-color !important;
    border: 1px solid $page-background-color !important;
  }
  .datepicker-error {
    border-color: $error-item-border-color !important;
  }
}

.react-datepicker__header {
  background-color: $page-background-color !important;
}

.custom-datepicker-header {
  .ui.button {
    background-color: $page-background-color !important;
  }
}

.optional-field:after {
  color: $optional-label-color;
}

.spinner > div {
  background-color: $primary-color;
}

.progress-top-bar-loader {
  .top-bar-item {
    background-color: $progress-top-bar-hover-gray;
  }
}

.custom-loader {
  .form-container {
    .loader-item {
      background-color: $progress-top-bar-hover-gray;
    }
  }
}

.ui.loader {
  position: relative !important;
}

.share-pdf-cmpt {
  color: $input-label-color;
}

.Toastify__toast--success {
  background-color: $success-container-background !important;
  color: $success-text-color !important;
  svg {
    color: $success-text-color !important;
  }
}

.Toastify__toast--warning {
  color: $warning-text-color !important;
  background-color: $warning-container-background !important;
  svg {
    color: $warning-text-color !important;
  }
}

.Toastify__toast--error {
  color: $error-text-color !important;
  background-color: $error-container-background !important;
  svg {
    color: $error-text-color !important;
  }
}

.Toastify__toast--info {
  color: $info-text-color !important;
  background-color: $info-container-background !important;
  svg {
    color: $info-text-color !important;
  }
}

.Toastify__toast--success,
.Toastify__toast--warning,
.Toastify__toast--error,
.Toastify__toast--info {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08) !important;
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08) !important;
  -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08) !important;
}

.accordion.ui.fluid .individual-accordion-container-error {
  border-color: $error-item-border-color;
}

.ui.selection.dropdown,
.ui.selection.dropdown:hover {
  background: $page-background-color;
  border-color: transparent;
}

.input-error .ui.fluid,
.input-error .ui.fluid:hover,
.input-error .ui.selection.active.dropdown,
.input-error .ui.selection.active.dropdown .menu {
  border-color: $error-item-border-color;
}

.shared-resume-control {
  .ui.fluid.inline.multiple.search.dropdown {
    background: page-background-color;
    border-color: transparent;
  }
}

.ui.modal {
  border: 1px solid $modal-border-color !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08) !important;
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08) !important;
  -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08) !important;
  border-radius: 15px;
}

.ui.modal > .close {
  color: $input-text-color !important;
  top: 2.5em;
  right: 2.5em;
}

@media only screen and (max-width: $medium-device) {
  .ui.modal > .close {
    top: 2.5rem !important;
    right: 2.5rem !important;
  }
}

.merge-modal-button {
  background: $modal-button-color !important;
  color: $white !important;
}

.popup.user-options .option {
  color: $titles-gray-color;
}

.popup.user-options .option:hover {
  background: $page-background-color;
}
.popup.download-options .ui.pointing.vertical.menu {
  .item {
    &:hover {
      background-color: $page-background-color;
    }
    a {
      color: $input-label-color;
    }
  }
}

.ui.pointing.vertical.menu {
  .item {
    color: $input-label-color;
  }
}

.ui.search > .ui.input {
  width: 282px;
  height: 41px;
}

.ui.cards > .card,
.ui.card {
  box-shadow: none;
  border-radius: 15px;
}

.ui.cards > .card .meta,
.ui.card .meta {
  color: $subtitles-gray-color;
}

.ui.cards a.card:hover,
a.ui.card:hover {
  box-shadow: none;
}

.ui.cards > .card > .content > .header:not(.ui),
.ui.card > .content > .header:not(.ui) {
  font-weight: normal;
  font-size: $fontSizeHeader;
  color: $titles-gray-color;
}

.ui.cards > a > .card > .content > .description {
  color: $menu-options-gray-color;
  & > .resume-card-icon {
    color: $primary-color;
  }
  & > div > .resume-card-icon {
    color: $primary-color;
  }
}

.ui.link.card:hover {
  box-shadow: none;
}

.ui.card.fluid.card-container {
  border-radius: 10px !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05) !important;
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05) !important;
  -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05) !important;
}

.ui.card.fluid.card-container-active {
  border-radius: 10px !important;
  border: 1px solid #f1f3f4 !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08) !important;
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08) !important;
  -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08) !important;
}

.ui.card.fluid.card-container-active .accordion-title,
.ui.card.fluid.card-container .accordion-title {
  font-weight: bold;
}

.ui.card.fluid.card-container-active,
.ui.card.fluid.card-container {
  .accordion-secondary-title,
  .accordion-title {
    color: $titles-gray-color;
  }
}

.ui.card.fluid.card-container-active,
.ui.card.fluid.card-container {
  .accordion-third-title {
    color: $menu-options-gray-color;
  }
}

.ui.card > .card-header {
  color: $secondary-color;
}

.card-description {
  color: $input-label-color;
  font-size: $descriptionSize;
}

.tag-skill {
  background-color: $primary-color;
}

.resume-right-tag-border {
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white 100%);
}

.resume-left-tag-border {
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white 100%);
}

.filtered-skills-right-border::after {
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white 100%);
}

.filtered-skills-left-border::before {
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white 100%);
}

.resume-card-container {
  .card-dropdown {
    .card-menu {
      .item {
        .title,
        .text {
          color: $subtitles-gray-color;
        }
        .option-icon {
          color: $primary-color;
        }
      }
    }
  }
}

.refinement-dropdown .ui.menu .item.disabled,
.refinement-dropdown .ui.menu .item.disabled:hover {
  background-color: $search-labels-color !important;
  color: $disabled-text !important;
  opacity: 1;
}

.modal-description {
  color: $input-label-color;
}

.confirm-button {
  background-color: $modal-button-color !important;
  color: white !important;
  font-size: $fontSizeHeader !important;
  .remove-icon {
    color: white !important;
  }
}

.photoSelector {
  .visible {
    border-radius: 8px !important;
    border-color: $drop-down-border-blue !important;
    .selected {
      color: $helper-icon-color !important;
    }
  }
}

.upload-picture-modal {
  .close.icon {
    top: 4px !important;
    right: 14px !important;
  }
}

.refinement-dropdown {
  position: relative;
  left: 5px !important;
}

@media only screen and (min-width: $mini-device) and (max-width: $medium-device) {
  .react-datepicker__triangle {
    left: 100px !important;
  }
}
