@import '../../../../Styles/color.scss';
@import '../../../../Styles/screen-sizes.scss';

.progressBottomBarGrid {
  bottom: 96px;
  position: fixed !important;
  z-index: 1;
  padding: 0px !important;
  height: 0px;
}

.progressBottomBarColumn {
  max-width: 382px;
}

.progressBottomBarContainer {
  margin: auto;
  padding: 16px 0px;
  border-radius: 12px;
  max-width: 382px !important;
  min-width: 100px !important;
  background-color: $white;
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  justify-items: center !important;
  box-shadow: 0px 1px 5px $icons-gray-color;

  .bottomBarItem {
    margin: 0px 19px;
    width: 32px !important;
    height: 32px !important;
    cursor: pointer;
    svg {
      width: 36px;
      height: 36px;
      stroke: $primary-color;
    }
    .circle {
      top: -6px;
      svg {
        height: 44px;
        width: 44px;
      }
    }
  }
}

@media only screen and (min-width: $medium-device) and (max-width: $huge-device) {
  .progressBottomBarGrid {
    max-width: 100% !important;
  }
}

@media only screen and (max-width: $medium-device) {
  .progressBottomBarColumn {
    max-width: 302px;
    min-width: 306px;
    .progressBottomBarContainer {
      .bottomBarItem {
        margin: 0px;
      }
    }
  }
}

@media only screen and (max-width: $tiny-device) {
  .progressBottomBarGrid {
    padding-bottom: 0px !important;
  }
}
