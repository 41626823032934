@import '../../../Styles/color.scss';
@import '../../../Styles/screen-sizes.scss';

.resume-card-link {
  margin: 12px;
  .resume-card-container {
    transform: none !important;
    width: 265px !important;
    height: 100px !important;
    max-height: 100px !important;
    .resume-card-title {
      display: flex !important;
      min-width: 0;
      .full-name {
        margin: 0;
        flex: 1 1 auto;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .status-indicator {
        margin-right: -1.5px;
        z-index: 200 !important;
        height: 20px;
        width: 20px;
      }
      .progress-icon-container {
        height: 20px;
        .check {
          stroke: $success-icons-color;
        }
        .warning {
          stroke: $warning-icons-color;
        }
      }
      .dot-options {
        width: 18px;
        height: 18px;
        vertical-align: sub;
      }
    }

    .avatar {
      margin: 0px 12px 0 0 !important;
      top: -3px;
    }

    .card-dropdown {
      animation: fade-in 0.6s;
      margin: 0px 0px 0px 3px;
      .card-menu {
        top: 20px;
        left: -100px;
        border-radius: 8px;
        border: none;
        .item {
          width: 100%;
          padding: 6px !important;
          display: flex;
          flex-flow: row;
          .title,
          .text {
            padding-left: 9px;
            font-size: 14px;
            float: left;
            width: 80px;
            margin: 0px !important;
          }
          .option-icon {
            width: 10%;
            margin-top: 5px;
            margin-left: 10px;
          }
          .custom-icon-option {
            margin: 0px 8px 0px 10px;
            width: 15px;
            max-height: 15px;
            stroke: $primary-color;
          }
        }

        .active.item {
          font-weight: normal;
        }

        .card-option:first-child,
        .sub-menu .item:first-child {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }

        .card-option:last-child,
        .sub-menu .item:last-child {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        .shared-pdf-hover {
          background-color: $option-hover;
        }

        #share-option {
          svg {
            margin-left: 7px;
          }

          .sub-menu::after {
            opacity: 0;
          }

          .sub-menu {
            border: none;
            height: max-content;
            top: 0% !important;
            border-radius: 8px !important;
            margin: 0px !important;
            .item {
              line-height: 20px;
            }
          }
        }
      }
    }

    .tags-container {
      position: absolute;
      padding-top: 8px;
      width: 95%;
      padding-top: 3px;
      max-height: 20px;
    }

    .filtered-skills-border-none,
    .filtered-skills-right-border,
    .filtered-skills-right-border-none,
    .filtered-skills-left-border,
    .filtered-skills-left-border-none {
      display: inline-block;
      max-width: 94%;
      height: 25px;
      user-select: none;
      overflow: hidden;
      white-space: nowrap;
      cursor: grab;
      max-height: 20px;
      border-radius: 4px !important;
    }

    .filtered-skills-right-border::after {
      position: absolute;
      content: '';
      height: 20px;
      width: 25px;
      right: calc(266px - 100%);
    }

    .filtered-right-border-none::after,
    .filtered-skills-left-border-none::before {
      content: none;
    }

    .filtered-skills-left-border::before {
      position: absolute;
      content: '';
      height: 20px;
      width: 25px;
      right: 0px;
      left: 0px;
    }

    .ui.floated.image {
      margin-bottom: 0.5em;
      object-fit: cover;
    }

    .tag-skill {
      color: $white;
      font-size: 13px;
      height: 20px;
      white-space: nowrap;
      display: inline-block;
      overflow: hidden;
      padding: 0px 5px;
      border-radius: 3px;
      margin: 0px auto;
    }

    .resume-right-tag-border {
      position: relative;
      top: -25px;
      right: 15px;
      clear: both;
      height: 20px;
      width: 25px;
      user-select: none;
      float: right;
      cursor: grab;
    }

    .resume-left-tag-border {
      position: absolute;
      clear: both;
      z-index: 1;
      height: 20px;
      width: 25px;
      user-select: none;
      float: left;
      cursor: grab;
    }

    .tag-skill + .tag-skill {
      margin-left: 2px;
    }

    .resume-card-footer {
      float: right;
      position: absolute;
      right: 14px;
      bottom: 14px;
      display: flex;
      bottom: 4px;
      &:hover {
        cursor: pointer;
      }
      .eye-icon {
        stroke: $primary-color;
        width: 20px;
        height: 20px;
        margin-right: 7px;
        animation: fade-in 0.6s;
      }
      .resume-card-icon {
        width: 20px;
        height: 20px;
        vertical-align: sub;
        animation: fade-in 0.6s;
      }
      .resume-card-download {
        user-select: none;
      }
    }
    .letter-avatar {
      width: 2em;
      height: 2em;
      line-height: 2em;
      border-radius: 500rem;
      margin: -3.5px 12px 0px 0;
    }
  }
  &:hover {
    border-radius: 16px;
    box-shadow: 0px 0px 6px $accordion-item-border-color !important;
    transition: all 0.4s cubic-bezier(0.25, 0.75, 0.25, 1);
  }
}

.spinner-container {
  display: flex;
  flex-direction: row;
}

.tags-popup {
  border-radius: 8px !important;
  opacity: 0.5;
  height: 28px;
  .content {
    margin-top: -2px;
  }
}

@media only screen and (max-width: $small-device) {
  .resume-card-container {
    min-width: -webkit-fill-available !important;
    min-width: -moz-available !important;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.resume-card-container.hidden-card {
  background-color: $hidden-background !important;
}

.hidden {
  opacity: 0.5;
}
