@import '../../../../Styles/color.scss';
@import '../../../../Styles/screen-sizes.scss';

$constant: 111;

.progress-side-bar-table {
  margin-top: 0px !important;
  width: 65px !important;
  background-color: $white;
  > div {
    padding: 1em 0.6em;
    text-align: center;
    border-bottom: solid 1px $page-background-color;
    display: inline-flex;
    width: 100%;
    &:last-of-type {
      border-bottom: none;
    }
    a {
      margin: auto;
      display: flex;
    }
  }
  .circle-progress-bar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    svg {
      position: relative;
      width: 45px;
      height: 45px;
      circle {
        fill: none;
        stroke-width: 3;
        transform: translate(5px, 5px);
        stroke-dasharray: $constant;
        stroke-dashoffset: $constant;
        stroke-linecap: round;
      }
    }
  }

  i.icon {
    margin: unset !important;
  }
}

.circle-progress-bar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  svg {
    position: relative;
    width: 45px;
    height: 45px;
    circle {
      fill: none;
      stroke-width: 3;
      transform: translate(5px, 5px);
      stroke-dasharray: $constant;
      stroke-dashoffset: $constant;
      stroke-linecap: round;
    }
  }
}

.side-bar-icon {
  line {
    stroke: $disabled-icon-color;
  }
  &:hover {
    cursor: pointer;
    line {
      stroke: $hover-icon-color;
    }
  }
}

.side-bar-icon:hover {
  color: $black !important;
}

.side-bar-download {
  cursor: pointer;
  margin: auto;
  padding-bottom: 10px !important;
  &:hover {
    line,
    polyline,
    path {
      stroke: $primary-color;
    }
  }
}

.download-container {
  .spinner {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.shared-container {
  display: inherit !important;
}
