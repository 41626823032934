@import '../../Styles/screen-sizes.scss';

.main-container {
  width: 100% !important;
  margin: 0px !important;
  height: 1px;
  min-height: 100%;
  display: inline-table !important;
}

@media only screen and (max-width: $large-device) {
  .main-container {
    width: 100% !important;
  }
}
