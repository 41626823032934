@import '../../Styles/color.scss';

.ResumeOptions {
  width: 42px;
  height: 42px;
  border-radius: 4px;
  background-color: $white;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  .iconSection {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    border: 2px solid #949494;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .dotsIcon {
      margin: 0 !important;
      color: #949494;
    }
  }
}
