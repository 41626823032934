@import '../../../../Styles/screen-sizes.scss';

.form-languages-container {
  margin: 25px 15px;
  #language.dropdown {
    .text,
    .label {
      max-width: 100%;
    }
    .menu .item,
    .text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

@media only screen and (max-width: $small-device) {
  .form-languages-container {
    margin: 12px 0px;
  }
}
