.custom-date-picker {
  .react-datepicker-wrapper {
    width: 100%;
    margin-left: 4px;
    padding-top: 0.28571429rem !important;
    .react-datepicker__input-container {
      input {
        padding-left: 16px !important;
      }
    }
  }
}
