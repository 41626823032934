@import '../../../../Styles/color.scss';

.checkboxContainer {
  border-radius: 4px;
  cursor: pointer;
  border: 2px solid rgb(200, 200, 200);

  &.checked {
    border: 2px solid $modal-button-color;
  }
  &:hover {
    border: 2px solid $modal-button-color !important;
  }

  .checkboxIcon {
    position: relative;
    width: 100%;
    height: 100%;

    .emptyCorner {
      position: absolute;
      width: 38%;
      height: 50%;
      color: inherit;
      background: $white;
      top: -2px;
      right: -2px;
    }

    .checkmark {
      display: inline-block;
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: -6px;
      right: -5px;

      .checkmarkStem {
        position: absolute;
        width: 2px;
        height: 13px;
        background-color: $modal-button-color;
        left: 53%;
        top: 4px;
      }

      .checkmarkKick {
        position: absolute;
        width: 5px;
        height: 2px;
        background-color: $modal-button-color;
        left: 7px;
        top: 16px;
      }
    }
  }
}

.svgIcon {
  cursor: pointer;
  &:hover {
    stroke: $modal-button-color;
  }
}
