@import '../../../../Styles/color.scss';
@import '../../../../Styles/screen-sizes.scss';

.profesional-summary-container {
  grid-column: span 2;
}

.profesional-summary-header {
  display: flex;
}

.profesional-summary-icon {
  flex-grow: 1;
  text-align: unset !important;
  margin-left: 10px;
}

.ui.form .field.field input:-webkit-autofill:focus {
  border-color: $page-background-color !important;
  -webkit-box-shadow: 0 0 0px 1000px $page-background-color inset !important;
  box-shadow: 0 0 0px 1000px $page-background-color inset !important;
}

.ui.form .field.field input:-webkit-autofill {
  border-color: $page-background-color !important;
  -webkit-box-shadow: 0 0 0px 1000px $page-background-color inset !important;
  box-shadow: 0 0 0px 1000px $page-background-color inset !important;
}

.form-personal-info-container {
  max-width: 878px;
  .dropdown {
    .text {
      max-width: 100%;
      white-space: nowrap;
    }
    .menu .item,
    .text {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .label-header {
    margin-bottom: 5px;
    display: flex;
    label {
      margin-right: 8px;
      font-size: 12px;
    }
  }
}

.hide-picture {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  margin-top: 15px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  width: 230px;
  div {
    width: 100%;
    text-align: right;
  }

  label {
    align-self: center;
    cursor: pointer;
    padding-top: 2px;
    padding-left: 5px;
  }

  .circle.outline,
  .check.circle.outline {
    cursor: pointer;
  }
}

.hide-picture:focus {
  outline: 1px dashed;
}

.upload-photo-container {
  margin-top: 22px !important;
  cursor: pointer;
  width: 132px;
  label {
    margin-left: 12px;
    font-size: 12px;
    font-weight: bold;
    color: $primary-color;
  }

  .label-with-avatar {
    vertical-align: -3px !important;
  }
}

@media only screen and (max-width: $medium-device - 1) {
  .hide-picture {
    font-size: 12px;
    padding-left: 2px;
    width: auto;
    div {
      width: 100%;
      text-align: left;
    }
  }
}
