@import '../../../../Styles/color.scss';
@import '../../../../Styles/screen-sizes.scss';

.sidebarContainer {
  width: 100%;

  .menuItem {
    font-size: 17px;
    padding-bottom: 8px !important;

    .menuItemIcon {
      &:before {
        content: '';
        position: absolute;
        top: 26px;
        right: 5px;
        height: 82%;
        width: 1px;
        border-left: 1px solid $icons-gray-color;
      }
    }

    .menuItemTitle {
      top: 3px;
      cursor: pointer;
      span {
        color: $description-color;
      }
    }

    .active {
      span {
        color: $secondary-color;
      }
    }

    .icon {
      height: 24px;
      width: 24px;
    }

    .numberIcon {
      stroke: $description-color;
    }

    .checkIcon {
      stroke: $success-icons-color;
    }

    .warningIcon {
      stroke: $warning-icons-color;
    }
  }
}

.sidebarActions {
  margin-top: 20px;

  .button {
    display: flex !important;
    align-items: center;
    justify-content: center;
    min-height: 42px;
    min-width: 132px;
    margin-right: 0 !important;

    .icon {
      margin: 0 5px 0 0;
      height: 20px;
      width: 23px;
      stroke: $white;
    }
  }
}

@media only screen and (min-width: $mini-device) and (max-width: $small-device) {
  .sidebarContainer {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .sidebarActions .button {
    margin: auto !important;
  }
}
