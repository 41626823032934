@import '../../../../Styles/color.scss';
@import '../../../../Styles/screen-sizes.scss';

.datepickerLabel {
  font-size: 12px;
  margin-left: 6px;
  color: $description-color !important;
}

.datePicker {
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  min-width: 194px !important;
}

.datePicker .optionalField:after {
  content: '(Optional)';
  margin-left: 10px;
  font-size: 10px;
  color: $optional-label-color;
}

.datePickerInput {
  background-color: $page-background-color !important;
  border: 1px solid $page-background-color !important;
}

.datepickerError {
  border-color: $secondary-color !important;
}
