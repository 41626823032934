@import '../../Styles/color.scss';
@import '../../Styles/screen-sizes.scss';

.topBarContainer {
  width: 100%;
  top: 0px;
  background-color: $white;
  height: 68px;
  box-shadow: 0 4px 2px -2px $progress-top-bar-hover-gray;
}

.topBarGrid {
  width: 96%;
  margin: auto !important;
}

.logo {
  width: 85px;
  &:hover {
    cursor: pointer;
  }
}

.menuGridRow {
  top: 3.5px;
}

.menu {
  font-size: 1.125rem !important;
  a {
    color: $menu-options-gray-color;
    padding-bottom: 14px;
    border-bottom: 3px solid transparent;
    span {
      padding-left: 6px !important;
    }
    &:hover {
      color: $menu-options-gray-color;
    }
  }
  .activeMenu {
    pointer-events: none;
    a {
      color: $secondary-color;
      font-weight: bold;
      border-bottom: 3px solid $secondary-color !important;
    }
  }
}

.mobileMenu {
  @extend .menu;
  padding-top: 6px !important;
  .column {
    padding-bottom: 8px;
    border-bottom: 3px solid transparent;
  }
  .activeMobileMenu {
    font-weight: bold;
    pointer-events: none;
    a {
      color: $secondary-color;
    }
    padding-bottom: 8px;
    border-bottom: 3px solid $secondary-color !important;
  }
}

.userProfileContainer {
  .userProfile {
    float: right !important;
  }
}

@media only screen and (min-width: $tablet-size + 1) {
  .topBarContainer {
    position: sticky;
    z-index: 6;
  }
}

@media only screen and (width: $medium-device) {
  .topBarContainer {
    height: 68px !important;
  }
}

@media only screen and (max-width: $medium-device) {
  .topBarContainer {
    width: 100%;
    background-color: $white;
    height: 96px;
  }

  .topBarGrid {
    width: 100%;
    margin: 0px !important;
  }

  .logo {
    width: 65px;
    height: 28px;
    top: 2px;
  }
}

@media only screen and (min-width: $small-device) and (max-width: $medium-device - 1) {
  .seatLogo {
    left: -36px;
  }
}
