@import '../../Styles/screen-sizes.scss';
@import '../../Styles/color.scss';

.PreviewDownloadContainer,
.PreviewDownloadContainerWhite {
  width: 100% !important;
  height: 100% !important;
  padding: 0px !important;
  margin: 0 !important;
  margin-top: -1rem !important;
  border: 0px none !important;

  .closeIcon {
    height: 30px;
    width: 30px;
    stroke: $tags-background-color;
    position: absolute;
    right: 1vw;
    top: 1vw;
    cursor: pointer;
    z-index: 200;
  }

  .rowOptions {
    display: flex !important;
  }
}

.PreviewDownloadContainer {
  background-color: $modal-background-color-template !important;
}

.PreviewDownloadContainerWhite {
  background-color: $white !important;
}

.container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.templatesModal {
  @extend .PreviewDownloadContainer;
  min-height: 100%;
  height: auto !important;
  background-color: #000000cc !important;
  padding: 24px 0px !important;
}

.button {
  display: flex !important;
  align-items: center;
  justify-content: center;
  min-height: 42px;
  min-width: 132px;
}

.icon {
  margin: 0 5px 0 0;
  height: 20px;
  width: 23px;
  stroke: $white;
}

.document {
  overflow: auto;
  user-select: none;
  position: relative;
  max-width: 100% !important;
  max-height: 100vh !important;
}

.page {
  margin: 12px;
}

.optionsContainer {
  background-color: #00000070;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  .previewDownloadGrid {
    min-height: 28px !important;
  }
  .smallDevices {
    margin-bottom: 20px;
  }
  .options {
    width: 100%;
    max-width: 650px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
  }
}

.templatesContainer {
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: medium;
  overflow: hidden;

  .cardTemplate {
    height: 445px;
    min-width: 304px !important;
    background-color: transparent !important;
    color: $white;
    transform: none !important;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .logo {
      width: 278px;
    }

    &:hover {
      background-color: $secondary-color !important;
    }
  }
}

@media only screen and (max-width: $small-device) {
  .templatesContainer {
    .cardTemplate {
      width: 100% !important;
    }
  }

  .closeIcon {
    right: 3vw !important;
  }

  .container {
    height: calc(100% - 48px);
    margin-top: 36px;

    .page {
      margin-top: 0;
    }
  }
}

@media only screen and (max-width: $tablet-size-to-avoid-overlap) {
  .computerDevice {
    display: none !important;
  }
}

@media only screen and (min-width: $mini-device) and (max-width: $small-device) {
  .templatesModal {
    padding-bottom: 0 !important;
  }
}
