@import '../color.scss';
@import '../screen-sizes.scss';

#modalDownload.ui.scrolling.modal.transition.visible.active,
#modalTemplate.ui.scrolling.modal.transition.visible.active {
  border: 0px none !important;
  border-radius: 0px !important;

  .react-pdf__Document {
    scrollbar-width: thin;
  }

  .page:hover {
    font-weight: bold;
  }

  .page.active {
    background: $black;
    color: $white;
    font-weight: bold;
  }

  .react-pdf__Page__canvas {
    margin: 0 auto;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.08) !important;
    -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08) !important;
    -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08) !important;
  }
}

#modalTemplate.ui.scrolling.modal.transition.visible.active {
  display: flex !important;
  align-items: center;
  justify-content: center;

  .ui.center.aligned.container .ui.cards {
    max-width: 1000px;
    margin-top: 0;
  }
}

@media only screen and (max-width: $normal-device) {
  #modalTemplate.ui.scrolling.modal.transition.visible.active {
    .ui.center.aligned.container {
      padding: 0 calc((100% - (#{$template-card-width} * 2)) / 2) !important;
    }
  }
}

@media only screen and (max-width: $max-width-two-template-cards) {
  #modalTemplate.ui.scrolling.modal.transition.visible.active {
    .ui.center.aligned.container {
      padding: 0 calc((100% - #{$template-card-width}) / 2) !important;
    }
  }
}
