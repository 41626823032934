@import '../../../../Styles/screen-sizes.scss';

.form-skills-container {
  margin-top: 25px;
  margin-bottom: 25px;
  .level-skill-section.field {
    label,
    .skills-level-icon {
      display: inline-block !important;
    }
  }
}

.area-section {
  margin-left: 15px;
}

.skills-level-icon {
  flex-grow: 1;
  text-align: unset !important;
  margin-left: 5px;
}

.visible.menu.transition > .item {
  line-height: 15pt !important;
}

div#area > .text,
div#skill > .text,
div#level > .text {
  width: 100px;
  height: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 100%;
}

div.selected.item.addition {
  font-style: italic;
}

@media only screen and (max-width: $small-device) {
  .form-skills-container {
    margin: 12px 0px;
  }
}
