.spinner {
  margin: 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;

  -webkit-animation: spinner-animation 1.4s infinite ease-in-out both;
  animation: spinner-animation 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.52s;
  animation-delay: -0.52s;
  margin-right: 2px;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
  margin-right: 2px;
}

@-webkit-keyframes spinner-animation {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(0.6);
  }
}
@keyframes spinner-animation {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
}
