@import '../../../Styles/color.scss';
@import '../../../Styles/screen-sizes.scss';

$leftSideBarContainerWith: 321px;
$desktopWidthReducePercentage: 19%;
$tabletWithReducePercentage: 8%;
$headerHeight: 75px;
$containerMargin: 11px;

.formGlobalContainer {
  max-width: 100% !important;
  height: calc(100vh - #{$heightTopNavBar}) !important;
  margin: 0 !important;

  .animation {
    position: relative;
    height: 100%;
    overflow: hidden;
    border-radius: 5px;

    .activity{
      position: absolute;
      left: -45%;
      height: 100%;
      width: 50%;
      background-image: linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
      background-image: -moz-linear-gradient(to left, rgba(251,251,251, .05), rgba(251,251,251, .3), rgba(251,251,251, .6), rgba(251,251,251, .3), rgba(251,251,251, .05));
      animation: loading 1.2s infinite;
    }

    .progressActivity {
      width: 100%;
      animation: loading 1.2s infinite;
    }
  }

  .formRow {
    height: 100% !important;
    padding: 0 !important;

    .progressSideBarGrid {
      min-width: 70px !important;
      right: 30px;
    }

    .formGrid {
      width: calc(100% - 320px) !important;
      height: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;

      .customLoader {
        display: flex;
        justify-content: center;
        overflow-y: auto;
        height: 100%;
        .progressSideBarContainer {
          min-width: 70px;
          margin-top: 25px !important;
          position: -webkit-sticky;
          position: sticky;
          top: 25px;
          margin-right: 16px;
        
          .loaderProgressSideBarTable {
            width: 65px;
            height: 344px;
            border-radius: 8px;
            background-color: $progress-top-bar-hover-gray;
          }
        }

        .formContainer {
          margin: 25px 0px 0px 0px !important;
          display: grid;
          align-items: center;
          row-gap: 60px;
          margin-bottom: 200px;
          width: 100%;
          max-width: $normal-device;
          padding-left: 20px;
          position: relative;

          & > div {
            margin-right: 20px;
          }

          .loaderItem {
            border-radius: 8px;
            align-items: center;
            justify-content: center;
            background-color: $progress-top-bar-hover-gray;
          }

          .personalInformation {
            height: 500px;
          }

          .skill {
            height: 300px;
          }

          .workExperience {
            height: 650px;
          }

          .education {
            height: 400px;
          }

          .language {
            height: 350px;
          }
        }
      }
    }

    .leftSidebarGrid {
      position: fixed;
      background-color: $hamburger-background-color;
      width: 320px !important;
      min-width: 320px !important;
      padding: 32px;

      .progressTopBarLoader {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 18px 18px 14px 14px;
        margin-left: 10px;
        padding-top: 1rem !important;
        width: 100%;
        height: 250px;

        .topBarItem {
          width: 100%;
          height: 28px;
          border-radius: 4px;
          background-color: $progress-top-bar-hover-gray !important;
        }
      }
    }
  }
}

.loaderContainer {
  height: 100vh;
  .image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 50px;
  }
}

@media only screen and (max-width: $normal-device + 11) {
  .formGlobalContainer {
    margin: 0px !important;
  }

  .formGrid {
    padding: 0px !important;
    min-width: 100% !important;
  }

  .formContainer {
    float: left;
    left: 10px;
    max-width: calc(100% - #{$containerMargin}) !important;
    padding: 0px !important;
  }

  .progressSideBarContainer {
    display: none;
  }
}

@media only screen and (width: $tablet-size) {
  .leftSidebarGrid {
    margin-top: 15px !important;
    padding-top: 4px;
  }
}

@media only screen and (min-width: $tablet-size + 1) {
  .leftSidebarGrid {
    margin-top: -12px !important;
  }
}
@media only screen and (max-width: $medium-device - 1) {
  .formGlobalContainer {
    height: calc(100vh - #{$heightTopNavBarTwoRows}) !important;
  }
}

@keyframes loading {
  0%{
      left: -45%;
  }
  100%{
      left: 100%;
  }
}
