@import '../../../../Styles//color.scss';

.grayPhoto {
  margin: -4px 6px -12px -3px;
}

.withoutPhoto {
  margin: -7px 9px -9px -3px;
}

.uploadItem {
  background-color: $primary-color;
  stroke: $white;
  padding: 4px;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  margin: -7px 11px -9px 1px;
}

.selectedPicture {
  margin-right: 9px !important;
}

.photoDropDown {
  vertical-align: -6px;
  color: $primary-color !important;
  font-size: 0.98rem;
  font-weight: none;
  i {
    margin-left: 5px !important;
    vertical-align: 5px !important;
  }
}
