.checkboxText {
  margin-left: 10px;
  cursor: pointer;
}

.sortItem,
.visibilityItem,
.filterItem {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  display: flex;
  align-items: center;
}
