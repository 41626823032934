@import '../../../../Styles/color.scss';
@import '../../../../Styles/screen-sizes.scss';

$leftSideBarContainerWith: 321px;
$desktopWidthReducePercentage: 19%;
$tabletWithReducePercentage: 8%;
$headerHeight: 88px;

.left-sidebar-grid {
  position: fixed;
  background-color: $hamburger-background-color;
  width: 320px !important;
  min-width: 320px !important;
  padding: 32px 0 !important;

  .left-sidebar-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: calc(100vh - #{$headerHeight});
    padding: 5px 32px 20px 32px;
    max-width: 320px;
  }
}

.progress-side-bar-grid {
  min-width: 70px !important;
  right: 30px;
  .progress-side-bar-container {
    min-width: 70px;
    margin-top: 40px !important;
  }
}

.form-container > div {
  margin-right: 20px;
}

.form-global-container {
  max-width: 100% !important;
  margin-left: inherit !important;
  margin: 0 !important;
  height: calc(100vh - #{$heightTopNavBar}) !important;
  .form-row {
    padding-bottom: 0 !important;
    height: 100% !important;
  }
}

.form-grid {
  width: calc(100% - 320px) !important;
  height: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;

  .form-sections {
    display: flex;
    justify-content: center;
    overflow-y: auto;
    height: 100%;
  }

  .progress-side-bar-container {
    min-width: 70px;
    margin-top: 25px !important;
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
    margin-right: 16px;
  }
}

.form-container {
  margin: 25px 0px 0px 0px !important;
  display: grid;
  align-items: center;
  row-gap: 60px;
  margin-bottom: 200px;
  width: 100%;
  max-width: $normal-device;
  padding-left: 20px;
  position: relative;
}

.form-row {
  padding-top: 0px !important;
}

@media only screen and (min-width: $tablet-size + 1) {
  .left-sidebar-grid {
    margin-top: -12px !important;
  }
}

.idle-time {
  .confirm-button {
    opacity: 1 !important;
  }
}

@media only screen and (max-width: $normal-device + 11) {
  .form-global-container {
    margin: 0px !important;
  }

  .form-grid {
    padding: 0px !important;
    min-width: 100% !important;
  }

  .form-container {
    float: left;
    left: 10px;
    max-width: calc(100% - 20px) !important;
    padding: 0px !important;
  }

  .progress-side-bar-container {
    display: none;
  }
}

@media only screen and (width: $tablet-size) {
  .left-sidebar-grid {
    padding-top: 4px;
  }
}

@media only screen and (max-width: $medium-device - 1) {
  .form-global-container {
    height: calc(100vh - #{$heightTopNavBarTwoRows}) !important;
  }
}

@media only screen and (max-width: $tiny-device) and (min-width: $mini-device - 39px) {
  #languages {
    padding-bottom: 70px;
  }
  .form-grid {
    .form-sections {
      justify-content: space-between;
    }
  }
  .form-container > div {
    margin-right: 0px;
  }
}
