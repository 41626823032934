@import '../../../../Styles/color.scss';

.refinementContainer {
  display: flex;
  padding-left: 5px;

  .title {
    white-space: nowrap;
    margin-right: 10px;
    margin-top: 10px;
    min-width: 61px;
  }

  .disabledText {
    color: $disabled-text;
  }

  .finalText {
    margin-left: 10px;
    margin-top: 10px;
    white-space: nowrap;
  }

  .menu {
    border-radius: 22px !important;
    margin-bottom: 10px !important;
    .dropdown {
      border-radius: 22px !important;
      border: none !important;
      .menu {
        border: none !important;
      }
    }
    .largeOption {
      width: 142px !important;
      min-width: 120px !important;
    }
  }

  .lastMenu {
    margin-left: -3.2px !important;
  }
}
