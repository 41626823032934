@import '../../Styles/color.scss';

.lettersAvatarProfile {
  float: left;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  font-size: 18px;
  text-align: center;
  line-height: 37px;
  color: $white;
  background-color: $avatar-background-color;
}

.lettersAvatarTiny {
  font-size: 11px;
  height: 26px;
  width: 26px;
  line-height: 26px;
}
