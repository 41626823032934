@import '../../../../Styles/color.scss';
@import '../../../../Styles/screen-sizes.scss';

.modal {
  max-width: 290px !important;
  min-width: 290px !important;

  .closeIcon {
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
  }
}

.showArea {
  border: dashed 1.5px $primary-color;
  border-radius: 2px;
}

.avatarEditorContainer {
  display: grid;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 140px;
  margin: auto !important;
  margin-top: 20px !important;

  .cropperContainer {
    min-width: 138px !important;
    height: 134px !important;
    position: relative;
  }

  .uploadContainer {
    position: absolute;
    width: 100% !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 102px !important;
    z-index: 1;
    label {
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $primary-color;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      svg {
        width: 32px;
        height: 32px;
        stroke: $white;
      }
    }

    .labelWithBorder {
      border: solid 2px $white !important;
    }

    .fileInput {
      display: none !important;
    }
  }

  .rangeContainer {
    margin-top: 10px;
    .slider {
      -webkit-appearance: none;
      width: 100%;
      height: 1px;
      background-color: $pdf-viewer-background;
      background: $pdf-viewer-background;
    }

    input[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      background: $white;
      cursor: pointer;
      border: 1px solid $pdf-viewer-background;
      box-shadow: 0 0 2px 0 $pdf-viewer-background;
    }

    input[type='range']::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }

    input[type='range']::-moz-range-thumb {
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background: $white;
      cursor: pointer;
      border: 1px solid $pdf-viewer-background;
      box-shadow: 0 0 2px 0 $pdf-viewer-background;
    }

    input[type='range']::-moz-range-runnable-track {
      box-shadow: none;
      border: none;
      background: transparent;
    }
  }

  .rangeContainerHide {
    visibility: hidden;
  }
}

.updateButton {
  background-color: $primary-color !important;
  color: $white !important;
  margin-top: 20px !important;
  height: 35px;
  width: 200px;
  width: 140%;
  font-size: 12px !important;
  margin-left: -27px !important;
  label {
    cursor: pointer;
  }
}

.switchContainer {
  position: relative;
  width: 100% !important;
  margin-top: 4px !important;
  z-index: 1;
}

.switch {
  float: right;
  position: relative;
  width: 20px;
  height: 10px;
  margin-right: 4px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $icons-gray-color;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 8px;
    width: 8px;
    left: 2px;
    top: 1px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: $primary-color;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $primary-color;
  }

  input:checked + .slider:before {
    transform: translateX(8.5px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.loading {
  margin-top: 33px !important;
}
