@import '../../Styles/screen-sizes.scss';

$bottomMenu: 100px;
$pageWidth: 650px;

.loadingContainer {
  height: calc(100vh - #{$bottomMenu});
  border-radius: 5px;
}

:global {
  .react-pdf__Document.document-viewer {
    overflow: auto;
    user-select: none;
    position: relative;
    max-width: 100% !important;

    canvas {
      width: 100% !important;
      height: auto !important;
      max-width: $pageWidth;
    }

    .react-pdf__Page {
      margin: 5px 5px;
    }

    .react-pdf__Page__canvas {
      margin: 0 auto;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.08) !important;
    }

    .react-pdf__Page__textContent {
      display: none;
    }
  }
  @media only screen and (max-width: $small-device) {
    .react-pdf__Document.document-viewer::-webkit-scrollbar {
      width: 4px;
    }
  }
}
